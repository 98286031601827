<template>
  <div>
    <div class="before">
      <ul class="list">
        <li
          class="item d-flex align-center justify-between"
          v-for="(item, index) in dailyList"
          :key="index"
        >
          <div class="item-l d-flex align-center">
            <img class="item-img" src="../../assets/img/before.png" alt="" />
            <div class="item-name">
              {{ item.name }}
              <div class="item-time">
                {{ item.testNumber }}人参考
                <span>有效期：{{ item.createTime }}</span>
              </div>
            </div>
          </div>
          <div class="item-r d-flex align-center">
            <!-- <div class="download d-flex align-center">
              <img src="../../assets/img/download.png" alt="" />
              下载
            </div> -->
            <div class="study" v-if="item.publish == 1">测试中</div>
            <div class="study-w" v-if="item.publish == 2">已完成</div>
            <div
              class="item-btn d-flex align-center justify-center cursor"
              :style="btn"
              @click="dailyBtn(item)"
            >
              开始做题
            </div>
          </div>
        </li>
      </ul>
      <div
        v-if="dailyList == ''"
        class="kong d-flex align-center flex-column justify-center"
      >
        <img class="kong-img" src="../../assets/img/kong.png" alt="" />
        <div class="kong-txt">暂无内容</div>
      </div>
      <v-pagination
        v-show="total > display"
        :total="total"
        :current-page="current"
        :display="display"
        @pagechange="pagechange"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/paginationUser";
export default {
  data() {
    return {
      btn: {
        backgroundImage: "url(" + require("../../assets/img/go_btn.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%"
      },
      dailyList: "",
      total: 0, // 记录总条数
      display: 12, // 每页显示条数
      current: 1, // 当前的页数
      id: ""
    };
  },
  props: ["subjectId"],
  watch: {
    subjectId(n, o) {
      //箭头函数  不然会发生this改变
      console.log(n);
      // console.log(o)
      this.id = n;
      this.getClass();
    }
  },
  components: {
    "v-pagination": pagination
  },
  mounted() {
    this.id = this.subjectId;
    this.getClass();
  },
  methods: {
    //科目列表
    async getClass(params) {
      let _this = this;
      let data = {
        subjectId: _this.id,
        examtype: 0,
        name: "",
        start: _this.current - 1,
        limit: _this.display
      }
      const res = await this.$ajaxRequest('get', 'paperList', data)
     _this.dailyList = res.data;
          _this.total = res.count;
    },
    // getClass() {
    //   let _this = this;
    //   _this.$api
    //     .paperList({
    //       subjectId: _this.id,
    //       examtype: 0,
    //       name: "",
    //       start: _this.current - 1,
    //       limit: _this.display
    //     })
    //     .then(res => {
    //       console.log(res);
    //       _this.dailyList = res.data;
    //       _this.total = res.count;
    //       // _this.subjectsIndex = res.data[0];
    //       // this.getCorrect();
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    pagechange(currentPage) {
      console.log(currentPage); //该参数就是当前点击的页码数
      // ajax请求, 向后台发送 currentPage, 来获取对应的数据
      this.current = currentPage;
      this.getlist();
      const currentY =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollAnimation(currentY, 0);
    },
    scrollAnimation(currentY, targetY) {
      // 获取当前位置方法
      // 计算需要移动的距离
      let needScrollTop = targetY - currentY;
      let _currentY = currentY;
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10);
        _currentY += dist;
        window.scrollTo(_currentY, currentY);
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY);
        } else {
          window.scrollTo(_currentY, targetY);
        }
      }, 1);
    },
    dailyBtn(item) {
      console.log(item);
      this.$router.push({
        path: `exam`,
        query: {
          subjectId: this.id,
          examtype: 0,
          examId: item.id,
          name: item.name
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.kong {
  margin-top: 50px;
  .kong-img {
    height: 210px;
    width: 280px;
  }
  .kong-txt {
    font-size: 20px;
    color: #999999;
    margin-top: 30px;
  }
}
.before {
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  margin-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  min-height: 443px;
  .list {
    padding: 26px 36px;
    box-sizing: border-box;
    .item {
      height: 96px;
      background: #ffffff;
      box-sizing: border-box;
      border-bottom: 1px solid #f6f9ff;
      .item-l {
        .item-img {
          height: 33px;
          width: 33px;
          margin-right: 20px;
        }
        .item-name {
          font-size: 18px;
          color: #333333;
          .item-time {
            color: #999999;
            font-size: 14px;
            span {
              margin-left: 23px;
              color: #999999;
              font-size: 14px;
            }
          }
        }
      }
      .item-r {
        .download {
          font-size: 16px;
          color: #999999;
          img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }
        .study {
          // margin-left: auto;
          margin-right: 20px;
          color: #5f2eff;
        }
        .study-w {
          // margin-left: auto;
          margin-right: 20px;
          color: #999999;
        }
        .item-btn {
          width: 104px;
          height: 43px;
          font-size: 16px;
          color: #ffffff;
          margin-left: 40px;
        }
      }
    }
  }
}
</style>
