<template>
  <div>
    <div class="daily" :style="note">
      <!-- <ul class="week d-flex align-center ">
        <li
          class="week-item d-flex flex-column align-center justify-center week_active"
        >
          <div class="week-name">星期四</div>
          2020-10-22
        </li>
        <li class="week-item d-flex flex-column align-center justify-center">
          <div class="week-name">星期四</div>
          2020-10-22
        </li>
        <li class="week-item d-flex flex-column align-center justify-center">
          <div class="week-name">星期四</div>
          2020-10-22
        </li>
        <li class="week-item d-flex flex-column align-center justify-center">
          <div class="week-name">星期四</div>
          2020-10-22
        </li>
        <li class="week-item d-flex flex-column align-center justify-center">
          <div class="week-name">星期四</div>
          2020-10-22
        </li>
        <li class="week-item d-flex flex-column align-center justify-center">
          <div class="week-name">星期四</div>
          2020-10-22
        </li>
        <li class="week-item d-flex flex-column align-center justify-center">
          <div class="week-name">星期四</div>
          2020-10-22
        </li>
      </ul> -->
      <ul class="list">
        <li
          v-for="(item, index) in dailyList"
          :key="index"
          class="item d-flex align-center justify-between"
        >
          <div class="item-l d-flex flex-column justify-between">
            <div class="item-title">{{ item.name }}</div>
            共{{ item.testNumber }}人参与练习
          </div>
          <div class="study" v-if="item.publish == 1">测试中</div>
          <div class="study-w" v-if="item.publish == 2">已完成</div>
          <div
            class="item-btn d-flex align-center justify-center cursor"
            :style="btn"
            @click="dailyBtn(item)"
          >
            去做题
          </div>
        </li>
        <!-- <li class="item d-flex align-center justify-between">
          <div class="item-l d-flex flex-column justify-between">
            <div class="item-title">幼儿综合素质</div>
            共141人参与练习
          </div>
          <div class="item-btn d-flex align-center justify-center" :style="btn">
            去做题
          </div>
        </li> -->
      </ul>
      <div
        v-if="dailyList == undefined"
        class="kong d-flex align-center flex-column justify-center"
      >
        <img class="kong-img" src="../../assets/img/kong.png" alt="" />
        <div class="kong-txt">暂无内容</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      note: {
        backgroundImage:
          "url(" + require("../../assets/img/daily_bg.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%"
      },
      btn: {
        backgroundImage: "url(" + require("../../assets/img/go_btn.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%"
      },
      id: "",
      dailyList: ""
    };
  },
  props: ["subjectId"],
  watch: {
    subjectId(n, o) {
      //箭头函数  不然会发生this改变
      console.log(n);
      // console.log(o)
      this.id = n;
      this.getClass();
    }
  },
  mounted() {
    console.log(this.subjectId);
    this.id = this.subjectId;
    this.getClass();
  },
  methods: {
    //科目列表
    async getClass(params) {
      let _this = this;
      let data = {
        subjectId: _this.id,
        name: "",
        start: 1,
        limit: 12
      }
      const res = await this.$ajaxRequest('get', 'dailyList', data)
      console.log(res.data);
      _this.dailyList = res.data;
    },
    // getClass() {
    //   let _this = this;
    //   _this.$api
    //     .dailyList({
    //       subjectId: _this.id,
    //       name: "",
    //       start: 1,
    //       limit: 12
    //     })
    //     .then(res => {
    //       console.log(res);
    //       _this.dailyList = res.data;
    //       // _this.subjectsIndex = res.data[0];
    //       // this.getCorrect();
    //       console.log(_this.dailyList);
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    dailyBtn(item) {
      console.log(item);
      this.$router.push({
        path: `exam`,
        query: {
          subjectId: this.id,
          examtype: 2,
          examId: item.id,
          name: item.name
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.kong {
  margin-top: 50px;
  .kong-img {
    height: 210px;
    width: 280px;
  }
  .kong-txt {
    font-size: 20px;
    color: #999999;
    margin-top: 30px;
  }
}
.study {
  margin-left: auto;
  margin-right: 20px;
  color: #5f2eff;
}
.study-w {
  margin-left: auto;
  margin-right: 20px;
  color: #999999;
}
.week_active {
  border-color: #5f2eff !important;
  color: #5f2eff !important;
  .week-name {
    color: #5f2eff !important;
  }
}
.daily {
  margin-top: 20px;
  min-height: 548px;
  background: #fcfbff;
  padding: 161px 76px 0px 76px;
  box-sizing: border-box;
  .week {
    width: 866px;
    height: 129px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
    .week-item {
      width: 123px;
      height: 100%;
      border-bottom: 3px solid transparent;
      box-sizing: border-box;
      font-size: 14px;
      color: #999999;
      .week-name {
        margin-bottom: 19px;
        font-size: 18px;
        color: #666666;
      }
    }
  }
  .list {
    .item {
      width: 866px;
      height: 80px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.1);
      padding: 20px 24px;
      box-sizing: border-box;
      margin-top: 20px;
      .item-l {
        font-size: 14px;
        color: #999999;
        .item-title {
          font-size: 18px;
          color: #333333;
        }
      }
      .item-btn {
        width: 80px;
        height: 32px;
        color: #ffffff;
        font-size: 16px;
      }
    }
  }
}
</style>
