<template>
  <div>
    <!-- 切换 -->
    <div class="tab">
      <div class="tab-title d-flex align-center">
        {{ subjectsIndex.subjectName }}
        <el-dropdown @command="handleCommand" trigger="click">
          <div class="tab-img cursor">
            <img src="../../assets/img/tab_title.png" alt="" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(it, idx) in subjects"
              :key="idx"
              :command="idx"
              >{{ it.subjectName }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <ul class="tab-lsit d-flex align-center">
        <li
          class="tab-item d-flex align-center justify-center flex-column cursor"
          @click="continueBtn"
        >
          <div class="tab-imgs">
            <img class="img1" src="../../assets/img/tab1.png" alt="" />
            <img class="img2" src="../../assets/img/tab1_active.png" alt="" />
          </div>
          继续上次
        </li>
        <li class="tab-line"></li>
        <li
          class="tab-item d-flex align-center justify-center flex-column cursor"
          @click="tab(0)"
          :class="{ tab_active: index == 0 }"
        >
          <div class="tab-imgs">
            <img class="img1" src="../../assets/img/tab2.png" alt="" />
            <img class="img2" src="../../assets/img/tab2_active.png" alt="" />
          </div>
          每日一练
        </li>
        <li class="tab-line"></li>
        <li
          class="tab-item d-flex align-center justify-center flex-column cursor"
          @click="tab(1)"
          :class="{ tab_active: index == 1 }"
        >
          <div class="tab-imgs">
            <img class="img1" src="../../assets/img/tab3.png" alt="" />
            <img class="img2" src="../../assets/img/tab3_active.png" alt="" />
          </div>
          阶段学习
        </li>
        <li class="tab-line"></li>
        <li
          class="tab-item d-flex align-center justify-center flex-column cursor"
          @click="tab(2)"
          :class="{ tab_active: index == 2 }"
        >
          <div class="tab-imgs">
            <img class="img1" src="../../assets/img/tab4.png" alt="" />
            <img class="img2" src="../../assets/img/tab4_active.png" alt="" />
          </div>
          历年真考
        </li>
        <li class="tab-line"></li>
        <li
          class="tab-item d-flex align-center justify-center flex-column cursor"
          @click="tab(3)"
          :class="{ tab_active: index == 3 }"
        >
          <div class="tab-imgs">
            <img class="img1" src="../../assets/img/tab5.png" alt="" />
            <img class="img2" src="../../assets/img/tab5_active.png" alt="" />
          </div>
          模考真题
        </li>
      </ul>
    </div>
    <!-- 统计 -->
    <div class="count d-flex" v-if="index != 2 && index != 3">
      <ul class="count-list d-flex align-center">
        <li class="count-item d-flex flex-column align-center justify-center">
          <div class="item-num">
            <span>{{ correct.accuracy }}</span> %
          </div>
          <div class="item-title">我的正确率</div>
        </li>
        <li class="count-line"></li>
        <li class="count-item d-flex flex-column align-center justify-center">
          <div class="item-num">
            <span>{{ correct.right }}</span> 道
          </div>
          <div class="item-title">正确题数（道）</div>
        </li>
        <li class="count-line"></li>
        <li class="count-item d-flex flex-column align-center justify-center">
          <div class="item-num">
            <span>{{ correct.error }}</span
            >道
          </div>
          <div class="item-title">错误题数（道）</div>
        </li>
        <li class="count-line"></li>
        <li class="count-item d-flex flex-column align-center justify-center">
          <div class="item-num">
            <span>{{ correct.count }}</span> 道
          </div>
          <div class="item-title">总题数（道）</div>
        </li>
      </ul>
      <div
        class="count-btn d-flex align-center flex-column justify-center"
        @click="goWrong"
      >
        <img src="../../assets/img/count1.png" alt="" />
        错题集
      </div>
      <div
        class="count-btn d-flex align-center flex-column justify-center"
        @click="goCollect"
      >
        <img src="../../assets/img/count2.png" alt="" />
        收藏夹
      </div>
      <div
        class="count-btn d-flex align-center flex-column justify-center"
        @click="goAnnal"
      >
        <img src="../../assets/img/count3.png" alt="" />
        做题记录
      </div>
    </div>
    <Daily
      :subject-id="subjectsIndex.subjectId"
      v-if="index == 0 && isReady"
    ></Daily>
    <Stage
      :subject-id="subjectsIndex.subjectId"
      v-if="index == 1 && isReady"
    ></Stage>
    <Before
      :subject-id="subjectsIndex.subjectId"
      v-if="index == 2 && isReady"
    ></Before>
    <Simulation
      :subject-id="subjectsIndex.subjectId"
      v-if="index == 3 && isReady"
    ></Simulation>
  </div>
</template>

<script>
import Daily from "../../components/tiku/daily";
import Stage from "../../components/tiku/stage";
import Before from "../../components/tiku/before";
import Simulation from "../../components/tiku/simulation";
export default {
  components: {
    Daily,
    Stage,
    Before,
    Simulation
  },
  data() {
    return {
      index: 0,
      subjects: "",
      subjectsIndex: "",
      correct: "",
      isReady: false
    };
  },
  mounted() {
    this.getClass();
  },
  methods: {
    tab(index) {
      this.index = index;
    },
    handleCommand(command) {
      this.subjectsIndex = this.subjects[command];
      // this.isReady = false;
      this.getCorrect();
      // this.isReady = false;
    },
    //科目列表
    async getClass(params) {
      let _this = this;
      const res = await this.$ajaxRequest('get', 'initDatatiku')
      console.log(res.data);
      _this.subjects = res.data;
      _this.subjectsIndex = res.data[0];
      this.getCorrect();
      this.isReady = true;
    },
    // 正确率
    async getCorrect(params) {
      let _this = this;
      let data = {
        subjectId: _this.subjectsIndex.subjectId
      }
      const res = await this.$ajaxRequest('get', 'accuracy', data)
      _this.correct = res.data;
    },
    //继续上次
    async continueBtn(params) {
      let _this = this;
      let data = {
        subjectId: _this.subjectsIndex.subjectId
      }
      const res = await this.$ajaxRequest('get', 'accuracy', data)
      if (res.message) {
        this.$confirm(res.message, "继续上次", {
          showCancelButton: false,
          showClose: false,
          confirmButtonText: "确定",
          type: "warning"
        }).then(res => { });
      } else {
        console.log(res);
        this.$router.push({
          path: `exam`,
          query: {
            subjectId: res.data.subjectId,
            examtype: res.data.examtype,
            examId: res.data.examId,
            name:
              res.data.userExamHistoryDetailsList[0].userExamHistory
                .examName
          }
        });
      }
    },
    // continueBtn() {
    //   let _this = this;
    //   console.log(_this.subjectsIndex);
    //   _this.$api
    //     .continue({
    //       subjectId: _this.subjectsIndex.id
    //     })
    //     .then(res => {
    //       if (res.message) {
    //         this.$confirm(res.message, "继续上次", {
    //           showCancelButton: false,
    //           showClose: false,
    //           confirmButtonText: "确定",
    //           type: "warning"
    //         }).then(res => { });
    //       } else {
    //         console.log(res);
    //         this.$router.push({
    //           path: `exam`,
    //           query: {
    //             subjectId: res.data.subjectId,
    //             examtype: res.data.examtype,
    //             examId: res.data.examId,
    //             name:
    //               res.data.userExamHistoryDetailsList[0].userExamHistory
    //                 .examName
    //           }
    //         });
    //       }
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    goWrong() {
      this.$router.push("wrong");
    },
    goCollect() {
      this.$router.push("collect");
    },
    goAnnal() {
      this.$router.push("annal");
    }
  }
};
</script>

<style lang="less" scoped>
//切换选中状态
.tab_active {
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-color: #5f2eff !important;
  color: #5f2eff;
  .img1 {
    opacity: 0 !important;
  }
  .img2 {
    top: 0 !important;
  }
}
//切换
.tab {
  height: 200px;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  background: #ffffff;
  padding: 0px 29px;
  box-sizing: border-box;
  .tab-title {
    padding: 36px 0px 22px 8px;
    font-size: 20px;
    font-weight: bold;
    color: #444444;
    box-sizing: border-box;
    border-bottom: 1px dashed #e7e8ef;
    .tab-img {
      width: 82px;
      height: 26px;
      margin-left: 20px;
    }
  }
  .tab-lsit {
    .tab-item {
      width: 198px;
      height: 115px;
      box-sizing: border-box;
      border-bottom: 3px solid transparent;
      cursor: pointer;
      .tab-imgs {
        height: 34px;
        width: 40px;
        margin-bottom: 14px;
        position: relative;
        overflow: hidden;
        .img1 {
          display: block;
          transition: 0.5s;
          -moz-transition: 0.5s;
          -webkit-transition: 0.5s;
          -o-transition: 0.5s;
        }
        .img2 {
          position: absolute;
          top: -40px;
          left: 0;
          transition: 0.5s;
          -moz-transition: 0.5s;
          -webkit-transition: 0.5s;
          -o-transition: 0.5s;
        }
      }
      &:hover .img1 {
        opacity: 0;
      }

      &:hover .img2 {
        top: 0;
      }
    }
    .tab-line {
      height: 27px;
      width: 1px;
      border-left: 1px dashed #e7e8ef;
    }
  }
}
//统计
.count {
  margin-top: 20px;
  .count-list {
    width: 610px;
    height: 120px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
    border-radius: 6px;
    .count-item {
      width: 152px;
      .item-num {
        font-size: 18px;
        color: #999999;
        span {
          font-size: 30px;
          color: #666666;
          font-weight: bold;
        }
      }
      .item-title {
        font-size: 14px;
        color: #999999;
        margin-top: 14px;
      }
    }
    .count-line {
      width: 1px;
      height: 57px;
      border-left: 1px dashed #e7e8ef;
    }
  }
  .count-btn {
    width: 120px;
    height: 120px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
    border-radius: 6px;
    font-size: 16px;
    color: #666666;
    margin-left: 10px;
    img {
      width: 60px;
      height: 63px;
    }
  }
}
</style>
